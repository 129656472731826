import { styled } from '@stitches/react';
import {
    Text,
    Global,
    Grid,
    BackButton
  } from '../UIComponents';
import ProjectHeader from '../ProjectHeader';
import ProjectFooter from '../ProjectFooter';
import { ImageBlock, Image } from '../Editorial'


function AlanApp() {
    Global();
    return (
        <div>
            <ProjectHeader 
                title='FlexCharging app and identity design'
                about="The FlexCharging app enables EV drivers to save money and decrease carbon emissions by matching their charging schedules with the lowest utility rates. The company had a working app that was built without design input. I designed the identity and the app, and I supported the product manager and engineer with the implementation."
                role='Role: Product and Brand Design'
                credits="Team: Ennio Dybeli, Ana Jamborcic"
            />

            <ImageBlock
                src='./assets/flex1.png'
                title="Modular experience adapted to user's schedule"
                p="The FlexCharging app incorporates three main flows: sign-up, charging location, and schedule set-up. The home screen has all the crucial information about usage and charging costs. As most car owners have more than one car, I've implemented a modular approach that displays as much information as possible within a given context."
            />

            <ImageBlock
                src='./assets/flex2.png'
                title="The initial version of the app"
                p="Before the redesign, the app was functional, but users struggled to find crucial information about savings and carbon emissions."
            />

            <ImageBlock
                src='./assets/flex3.png'
                title="Cheaper and greener charging"
                p="Setting up the location and charging schedule. If a user prefers to charge at night at her house, FlexCharging will match the user's charging schedule with the cheapest rate."
            />

            <Image
                src='./assets/flex4.png'
            />

            <ImageBlock
                src='./assets/flex5.png'
                title="New visual language "
                p="To support FlexCharging's mission to bring economical and greener charging to every EV, the brand is simple, clean, and trustworthy. The logo is inspired by the concept of electric charging, and the patterns imitate the marks of a car tire. A predominantly monochromatic colour palette allows for easy switching between dark and light modes without breaking the contrast ratio."
            />

            <ProjectFooter url='/typeform' />

        </div>
    );
};

export default AlanApp;
