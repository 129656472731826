import { styled } from './stitches.config';
import {
    Text,
    Global,
  } from './UIComponents'
  import Arrow from './assets/arrow2.svg'


const FooterWrapper = styled('a',{ 
    borderTop: '1px solid #BBBBBB',
    marginTop: '48px',
    transitionDuration: '.3s',
    '@bp1':{
        paddingTop: '32px',
        display: 'grid',
        gridGap: '24px',
        margin: '24px'
    },
    '@bp2':{
        paddingTop: '16px',
        paddingBottom: '24px'
    },
    '&:hover': {
        '& .link': {
            transitionDuration: '.3s',
            opacity:'.5'
        }
    }
});

const Links = styled('div',{
    display: 'flex',
    alignItems:'center',
    justifyContent: 'space-between'
})

const Icon = styled('img',{
    height: '12px',
    width: '12px',
    marginLeft: '8px',
    marginTop: '5px',
    transitionDuration: '.4s',
})



function ProjectFooter(props) {
    Global();
    return (
        <FooterWrapper href={props.url}>
            <Links className="link">
                <Text>Next project</Text>
                <Icon src={Arrow}/>
            </Links>
        </FooterWrapper>
    );
};

export default ProjectFooter;
