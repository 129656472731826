import { styled, globalCss } from './stitches.config';

const Global = globalCss({

    'body':{
      fontFamily: '"GTF", system-ui',
      color: '#111',
      margin: '0',
      '-webkit-font-smoothing': 'antialiased',
      lineHeight: '1.3',
      fontSize: '1.2rem'
    },
    'a': { 
      color:'#111',
      textDecoration:'none',
      fontFamily: 'unset'
    },
    'p': {
      margin:'0'
    }
  });

const Text = styled('p', {

    variants: {
        size: {
            base: {
                fontSize: '1rem'
            },
  
            display: {
                fontSize: '4rem',
                letterSpacing: '-2px',
                lineHeight: '1.1'
            },

            header: {
                fontSize: '2rem',
                letterSpacing: '-1px',
                lineHeight: '1.2'
            },
        
            description: {
                fontSize: '1rem',
                opacity: '0.6'
            },

            micro: {
                fontSize: '0.8rem',
                opacity: '0.6'
            },

            subheader: {
                fontSize: '2rem',
                letterSpacing: '-1px',
                lineHeight: '1.2'
            }
        },

        bold: {
            true:{
                fontWeight: '500'
            }
        },

        dimmed: {
            true:{
                opacity: '.5'
            }
        },
    }
  });


const Wrapper = styled('div',{
    '@bp1': {
      margin: '24px 24px',
    },
    '@bp2': {
        // margin: '48px 80px',
    }
  })
  
  const Section = styled('div',{
    '@bp1':{
        marginBottom: '48px'
    },
    '@bp2':{
        marginBottom: '128px'
    },
    variants: {
      header: {
        true: {
            '@bp1':{
                marginBottom: '20vh'
            },
            '@bp2':{
                marginBottom: '32vh'
            }
        }
      }
    }
  })
  
  const Grid = styled('div',{
    '@bp1':{
        display:'grid',
        gridGap:'24px'
    },
    '@bp2':{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '48px',
        'p:nth-child(3)': {
            textAlign: 'right'
        },
    }
  })

  const VideoWrapper = styled('div',{
    '@bp1': {
      margin: '0px 24px',
      '& video': {
        borderRadius: '16px',
      }

    },
    '@bp2': {
        margin: '0px 24px',
        '& video': {
          borderRadius: '64px',
        }
    },
    variants: {
      homepage: {
        true: {
          margin: '0px !important'
        }
      }
    }
})

  
  const Footer = styled('div',{
    display: 'flex',
    justifyContent: 'space-between'
  })  

  export {
      Text,
      Global,
      Wrapper,
      Section,
      Grid,
      Footer,
      VideoWrapper
    }
