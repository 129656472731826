import { styled } from './stitches.config';
import {
    Text,
    Global,
    Grid
  } from './UIComponents'


const ContentWrapper = styled('div',{
    '@bp1':{
        padding: '24px',
    },
    '@bp2':{
        // padding: '24px 80px',
    }
})

const TextWrapper = styled('div',{
    '@bp1':{
        padding: '0px 24px',
    },
    '@bp2':{
        // padding: '0px 80px',
    }
})

const TextContent = styled('div',{
    '@bp1': {
        display: 'grid',
        gridGap:'16px',
        paddingTop: '16px'

    },
    '@bp2': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap:'48px',
        marginBottom:'80px',
        paddingTop: '40px'
    }
})

const ImageContent = styled('div',{
    // marginBottom: '24px',
    '& img': {
        width: '100%'
    }
})


// const SectionTitle = styled('div',{
//     display: 'grid',
//     '@bp1':{ marginBottom: '8px'},
//     '@bp2':{marginBottom: '24px'}
// })

function Image(props) {
    return (

        <ContentWrapper>

            <ImageContent>
                <img src={props.src} alt='project preview'/>
            </ImageContent>
    
        </ContentWrapper>
    );
};


function ImageBlock(props) {
    return (

        <ContentWrapper>

            <ImageContent>
                <img src={props.src} alt='project preview'/>
                <Text size='micro'> {props.label}</Text>
            </ImageContent>
            
            <TextContent>
                <Text size='subheader'>{props.title}</Text>
                <Text> {props.p} </Text>
            </TextContent>

        </ContentWrapper>
    );
};

function TextBlock(props) {
    Global();
    return (

        <TextWrapper>

            <TextContent>
                <Text size='subheader' >{props.title}</Text>
                <Text> {props.p} </Text>
            </TextContent>

        </TextWrapper>
    );
};

export {
    ImageBlock,
    Image,
    TextBlock
};
