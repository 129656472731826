import { styled } from './stitches.config';

import Img_AlanApp from './assets/homepage/alanapp.png'
import Img_AlanBrand from './assets/homepage/alanbrand.png'
import Img_Typeform from './assets/homepage/typeform.png'
import Img_Flexcharger from './assets/homepage/flexcharger.png'
import Img_Back from './assets/homepage/back.png'
import VideoLink from './assets/videos/homepageLoop.mp4';


import {
  Text,
  Global,
  Wrapper,
  Section,
  Grid,
  Footer,
  VideoWrapper
} from './UIComponents'


const ProjectHeader = styled('div',{
  borderTop:'1px solid #BBBBBB',
  '@bp1':{
    padding:'16px 0 24px 0'
  },
  '@bp2':{
    padding:'24px 0 40px 0'
  }
})

const ProjectImage = styled('img',{
  background: '#EBEEFF',
  width: '100%',
  '@bp1':{
    borderRadius: '12px',  },
  '@bp2':{
    borderRadius: '64px',
  }

})

const ProjectPreview = styled('a',{
  '& img': {
    transitionDuration: '.9s',
    transitionTimingFunction: 'ease-out;',
  },
  '& img:hover': {
    transform: 'scale(0.99)',
  }
})

const Dist = styled('div',{
  '@bp1':{
    '& a':{
      marginRight: '16px',
      '&:hover':{
        opacity: '0.6'
      }
    } 
  },
  '@bp2':{
    textAlign: 'right',
    '& a':{
      marginLeft: '32px',
      '&:hover':{
        opacity: '0.6'
      }
    }
  }

})


const DateAligner = styled('div',{
  '@bp1':{
    display:'grid',
    gridGap:'12px'
  },
  '@bp2':{
    display:'grid',
    gridTemplateColumns: '0.15fr 1fr',
  }

})

function ProjectItem(props) {
  return (
        <Section>
          <ProjectPreview href={props.url}>

          <ProjectHeader>
            <Grid>
              <DateAligner>
                <Text size="description">{props.projectDate}</Text>
                <Text size='header'>{props.projectTitle}</Text>
              </DateAligner>
              <Text>{props.projectDesc}</Text>
              <Text size="description"> {props.projectLabels} </Text>
            </Grid>
          </ProjectHeader>
            <ProjectImage srcset={props.projectImg + ""} src={props.projectImg}/>
          </ProjectPreview>
        </Section>

  );
}

function VideoItem(props) {
  return (
        <Section>
          <ProjectPreview href={props.url}>

          <ProjectHeader>
            <Grid>
              <DateAligner>
                <Text size="description">{props.projectDate}</Text>
                <Text size='header'>{props.projectTitle}</Text>
              </DateAligner>
              <Text>{props.projectDesc}</Text>
              <Text size="description"> {props.projectLabels} </Text>
            </Grid>
          </ProjectHeader>
            <VideoWrapper homepage>
              <video preload="auto" width="100%" height="100%" autoPlay playsinline loop muted playsinline="" webkit-playsinline="" x5-playsinline="">
                    <source src={VideoLink} type="video/mp4" />
              </video>  
            </VideoWrapper>
          </ProjectPreview>
        </Section>

  );
}


function Homepage() {
  Global();
  return (
      <Wrapper>

        <Section header>
          <Grid>
            <Text>Maja Cerar</Text>
            <Text>San Francisco-based designer specializing in the intersection of brand identity and digital product design</Text>
            <Dist>
              <a href="mailto:cerar.maja@gmail.com" target="_blank" > Email </a>
              <a href="https://twitter.com/majacerar" target="_blank" > Twitter </a>
              <a href="./resume-maja-cerar.pdf" target="_blank" > Résumé </a>
            </Dist>
          </Grid>
        </Section>

        <ProjectItem 
          projectDate="2021" 
          projectTitle="Alan app redesign"
          projectDesc="Leading redesign of Alan product for a new generation of healthcare service"
          projectLabels="Product design, strategy, design system"
          projectImg={Img_AlanApp}
          url="/alan-app"
        />

        <ProjectItem 
          projectDate="2020" 
          projectTitle="Alan brand refresh"
          projectDesc="Evolving brand identity into a consistent media and product language"
          projectLabels="Creative direction, iconography, brand design"
          projectImg={Img_AlanBrand}
          url="/alan-brand"
        />
      
        <ProjectItem
          projectDate="2019" 
          projectTitle="Back platform design"
          projectDesc="Creating and implementing a new visual language for the Back platform"
          projectLabels="Product design, design system"
          projectImg={Img_Back}
          url="/backhq"
        />

    
        <ProjectItem 
          projectDate="2019"
          projectTitle="FlexCharging app and identity design"
          projectDesc="Designing identity and product for an electric vehicle charging app"
          projectLabels="Product design, brand design"
          projectImg={Img_Flexcharger}
          url="/flexcharger"
        />
            
        <VideoItem 
          projectDate="2018"
          projectTitle="Typeform product vision"
          projectDesc="Developing Typeform product vision videos from idea to final execution"
          projectLabels="Research, storyboarding, art direction, design"
          projectImg={Img_Typeform}
          url="/typeform"
        />
      
      <Footer>
        <Text size='description'>Majacerar.design </Text>
        <Text size='description'>2021 © All Rights Reserved</Text>
      </Footer>
        
      </Wrapper>
  );
}

export default Homepage;
