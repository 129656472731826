import { styled } from '@stitches/react';
import {
    Text,
    Global,
    Grid,
    BackButton,
    VideoWrapper
  } from '../UIComponents';
  import ProjectHeader from '../ProjectHeader';
import { TextBlock, ImageBlock } from '../Editorial'
import ProjectFooter from '../ProjectFooter';
import VideoLink from '../assets/videos/typeform2.mov';
import VideoLink2 from '../assets/videos/typeform3.mp4';


function AlanApp() {
    Global();
    return (
        <div>
            <ProjectHeader 
                title='Typeform product vision'
                about="Typeform is an engaging and beautiful form builder. Product vision videos were my final project in almost six amazing years at the company. Product vision was a North Star in defining Typeform's position in 2020. I designed two concepts, starting with research and storyboarding. These screens were later on included in the final videos."
                role='Role: Research, Storyboarding, Art direction, Design'
                credits="Team: Andy Gray, Jeff King, Ennio Dybeli, Chris Linnet"
            />

            <VideoWrapper>
                <video preload="auto" width="100%" height="100%"  autoPlay playsinline loop muted playsinline="" webkit-playsinline="" x5-playsinline="">
                    <source src={VideoLink} type="video/mp4" />
                </video>  
            </VideoWrapper>

            <ImageBlock
                src='./assets/typeform1.png'
                title="Helping companies to recruit target audience"
                p="Audience service helps Typeform customers to collect additional data by targeting certain demographics from the Typeform respondent database. This is important, as many companies use Typeform to collect various data from target demographics to get feedback on their products. The visualization above shows how the service would work on the company's and the respondent's side (respondent profile). "
            />

            <VideoWrapper>
                <video preload="auto" width="100%" height="100%"  autoPlay playsinline loop muted playsinline="" webkit-playsinline="" x5-playsinline="">
                    <source src={VideoLink2} type="video/mp4" />
                </video>  
            </VideoWrapper>

            <TextBlock
                title="Forms on the go"
                p="The second product vision topic was cross-platform forms. The idea behind it was to explore how forms would fit seamlessly into the respondent's lifestyle. In this concept, respondents can fill in the form at any point of the day and save partial responses to the device of their preference. Besides mobile, Typeforms can also be filled in via voice and, in later stages, on wearables. Once the form is saved, the respondent can reopen it at any time. "
            />
            <ProjectFooter url='/alan-app' />
        </div>
    );
};

export default AlanApp;
