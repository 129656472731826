import React from 'react';
import ReactDOM from 'react-dom';
import Homepage from './Homepage';

import AlanApp from './works/AlanApp';
import AlanBrand from './works/AlanBrand';
import Backhq from './works/Backhq';
import Flexcharger from './works/Flexcharger';
import Typeform from './works/Typeform';


import { BrowserRouter, Routes, Route } from "react-router-dom";


import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
       <Route path="/" element={<Homepage />} />
       <Route path="/alan-app" element={<AlanApp />} />
       <Route path="/alan-brand" element={<AlanBrand />} />
       <Route path="/backhq" element={<Backhq />} />
       <Route path="/flexcharger" element={<Flexcharger />} />
       <Route path="/typeform" element={<Typeform />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
