import { styled } from '@stitches/react';
import {
    Text,
    Global,
    Grid,
    VideoWrapper
  } from '../UIComponents';
import ProjectHeader from '../ProjectHeader';
import ProjectFooter from '../ProjectFooter';
import { ImageBlock, Image, TextBlock } from '../Editorial'
import VideoLink from '../assets/videos/back1.mp4';



function AlanApp() {
    Global();
    return (
        <div>
            <ProjectHeader 
                title='BackHQ platform design'
                about="Back is a platform that helps HR departments handle employee requests like payroll or parental leave questions. I joined the company in 2020 as a second product designer to improve the UI and build their design system. I redesigned 90% of the platform, including the home page, main request dashboard, and the report section of the app."
                role='Role: Product Design, Design System'
                credits="Team: Claus Hollensteiner, Jan Ustohal, and Rob Strom"
            />

            <VideoWrapper>
                <video preload="auto" width="100%" height="100%"  autoPlay playsinline loop muted playsinline="" webkit-playsinline="" x5-playsinline="">
                    <source src={VideoLink} type="video/mp4" />
                </video>  
            </VideoWrapper>


            <TextBlock
                title="Organizing employee requests"
                p="HR departments spend lots of time supporting employees. Since requests come from multiple channels, HR managers struggle with tracking these and measuring team performance. A centralized page where requests can be viewed by status (Kanban view) or time (List view) allows managers to quickly respond and organize requests in their preferred order. In this way, they can resolve repetitive requests faster and focus on more-strategic projects."
            />


            <Image
                src='./assets/back2.png'
            />

            <ImageBlock
                src='./assets/back3.png'
                title="Team productivity at a glance"
                p="The request dashboard allows HR managers to track team productivity and allocate resources accordingly. When deciding which data to include on the dashboard, we followed HR team KPIs, such as the number of requests over time or per category and the time taken to resolve requests."
            />

            <ProjectFooter url='/flexcharger' />

        </div>
    );
};

export default AlanApp;
