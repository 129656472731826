import {
    Global,
    VideoWrapper
  } from '../UIComponents';
import ProjectHeader from '../ProjectHeader';
import ProjectFooter from '../ProjectFooter';
import { ImageBlock, TextBlock } from '../Editorial';
import VideoLink from '../assets/videos/iconsvideo.mp4';

function AlanApp() {
    Global();
    return (
        <div>
            <ProjectHeader 
                title='Alan brand refresh'
                about="One of my first projects at Alan was a brand refresh. The project goal was to increase brand awareness and establish a scalable identity for Alan's growing set of products. "
                role='Role: Creative Direction, Iconography, Branding'
                credits='Team: Tiphaine Gillet, Edouard Watier, Aiswarya Kolisetty, Laure Boutmy'
            />

            <VideoWrapper>
                <video preload="auto" width="100%" height="100%"  autoPlay playsinline loop muted playsinline="" webkit-playsinline="" x5-playsinline="">
                    <source src={VideoLink} type="video/mp4" />
                </video>
            </VideoWrapper>

            <ImageBlock
                src='./assets/alanBrand1.png'
                title="Icons with a touch of marmot"
                p="The refresh included grid and colour palette changes, illustrations, and iconography. The latter was a major part of the project as we were replacing overused emojis with a set of custom icons. The icons' shape derives from the curvature of Alan's logo. This added character while ensuring clarity and readability. "
            />

            <ImageBlock
                src='./assets/alanBrand2.png'
                title="Blurry gradients for extra softness"
                p="The gradient library was built on top of Alan's colour palette. The library ranges from fresher to warmer tones, allowing for more flexibility when used for a variety of Alan products, like Alan Mind and Baby. We also added blur as a distinctive style effect to enhance soft and fluid sensations. "
            />

                <ProjectFooter url='/backhq' />


        </div>
    );
};

export default AlanApp;
