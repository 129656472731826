import { styled } from './stitches.config';
import BackArrow from './assets/arrow.svg'
import {
    Text,
    Global,
    Grid
  } from './UIComponents'

  const BackButton = styled('a',{
    fontSize: '1rem',
    background: 'white',
    padding: '6px 12px',
    borderRadius: '99px',
    marginBottom: '32px',
    transition: '.3s',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    '&:hover' : {
        '& img': {
            transitionDuration: '.4s',
            marginRight: '4px'
        }
    }
  })

const Icon = styled('img',{
    height: '12px',
    width: '12px',
    marginRight: '8px',
    transitionDuration: '.4s',
})

const HeaderWrapper = styled('div',{ 
    background:'#f4f4f4',
    height: '100%;',
    marginBottom: '40px',
    '@bp1':{
        padding: '24px',
    },
    '@bp2':{
        // padding: '48px 80px',
    },
});

const HeaderGrid = styled('div',{
    '@bp1':{
        display: 'grid',
        gridGap:'24px'
    },
    '@bp2':{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap:'48px'
    },

})

const Description = styled('div',{
    display: 'grid',
    gridGap: '16px'
})


function ProjectHeader(props) {
    Global();
    return (
        <HeaderWrapper>
            <BackButton href='/'>
                <Icon src={BackArrow}/>
                All projects
            </BackButton>
            <HeaderGrid>
                <Text size={{'@bp1':'subheader','@bp2':'display'}} > {props.title} </Text>
                <Description>
                    <Text> {props.about} </Text>
                    <Text dimmed > {props.role} </Text>
                    <Text dimmed > {props.credits} </Text>
                </Description>
            </HeaderGrid>
        </HeaderWrapper>
    );
};

export default ProjectHeader;
