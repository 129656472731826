import {
    Text,
    Global,
    Grid,
    BackButton
  } from '../UIComponents';
import ProjectHeader from '../ProjectHeader';
import { ImageBlock, Image } from '../Editorial'
import ProjectFooter from '../ProjectFooter';
import Img_AlanApp from '../assets/homepage/alanapp.png'


function AlanApp() {
    Global();
    return (
        <div>

            <ProjectHeader 
                title='Alan app redesign'
                about="Alan is a digital health insurance provider. In 2020, I joined the team as a UI designer to design a visual concept for the app. The goal was to develop a more refined UI to improve the perceived quality of Alan's digital products."
                role='Role: Product design, Strategy, Design system'
                credits='Team: Laure Boutmy, Pierrick Boutruche, Tiphaine Gillet, Edouard Wautier'
            />

            <ImageBlock
                src='./assets/alanApp1.png'
                title="Healthcare Super app"
                p="Alan is not just a digital insurance app. It's your health partner that supports you throughout your health journey, from prevention to post-doctor care. The app redesign brings all these superpowers to the surface. Crucial health services are paired with your most relevant health events, like dental or gynaecological appointments. A softer and warmer visual palette sets a friendly tone and enhances customer trust in the brand."
            />

            <ImageBlock
                src='./assets/alanApp2.png'
                title="Emoji-land"
                p="The previous visual language was difficult to scale and made room for inconsistencies. Emojis were used instead of icons and spot illustrations, which made it harder to establish visual hierarchy. However, fundamental elements like typography and colour palette were refined and incorporated into the new language."
            />

            <Image
                src='./assets/alanApp3.png'
            />

            <ImageBlock
                src='./assets/alanApp4.png'
                title="Design system update"
                p="The brand refresh brought some new style elements and components to the design system. We changed the design of inputs and updated active and hover states. A gradient effect was added to the headers, and the layout of the card was changed to cater to different types of content. We also changed the grid from 6 px to 4 px."
            />

         
            <ProjectFooter url='/alan-brand' />

        </div>

    );
};

export default AlanApp;
